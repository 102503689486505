import Config, {Env} from "./config.types";
import {DataQueryResultFunction} from "../components/DataTable/types";
import csuClient from "../network";

const config: Config = {
    appName: 'MINT',
    env: (process.env.REACT_APP_ENV as Env),
    language: {
        defaultValue: 'fr',
        available: ['en', 'fr'],
    },

    storagePrefix: 'mint__',

     //mintClient: 'http://10.10.10.18:8003/api/',
    mintClient: 'http://apsr-mint.cm:8022/api/',
    //mintClient: 'http://3.137.55.229:8021/api/',
    // mintClient: 'http://localhost:5062/api/',
    // mintClient: 'http://192.168.9.2:8022/api/',

    ui: {
        breakingPointSize: {
            mobile: Number(process.env.REACT_APP_MOBILE_BREAKPOINT_SIZE) || 768,
            aside: Number(process.env.REACT_APP_ASIDE_MINIMIZE_BREAKPOINT_SIZE) || 1024
        }
    },
    dataTable: {
        defaultPageSize: 10,
    }
};

export default config;

